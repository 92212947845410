import React, {useEffect, useState} from "react";
import {IUser, USERNAME_REGEX} from "../Types";
import {useAPI} from "../../utilities/useAPI";
import UserPropertiesAdmin from "./UserPropertiesAdmin";
import CustomInputForm from "../common/CustomInputForm";
import {NotificationManager} from 'react-notifications';

const UserAdmin = () => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [search, setSearch] = useState<string>("");
    const {doFetch} = useAPI();

    const setUsersWithPlaceholder = (users) => {
        //add empty user to "create" a new user
        users.unshift({username: "", firstName: "", lastName: "", email: "", printer: null});
        setUsers([]); // triggers a complete repaint, otherwise the children are not updated
        setUsers(users);

    }

    const findAllUsers = () => {
        doFetch<IUser[]>("/api/user/all").then((res) => {
            setUsersWithPlaceholder(res);
        });
    }

    useEffect(() => {
        findAllUsers();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = (user) => {
        if (user.username === undefined || user.username === "" || user.username.length < 1) {
            NotificationManager.error("Gebruikersnaam mag niet leeg zijn!")
            return;
        }
        if (user.username.length < 4 || user.username.length > 28) {
            NotificationManager.error("Gebruikersnaam moet tussen 4 en 28 karakters lang zijn.")
            return;
        }

        if (!user.username.match(USERNAME_REGEX)) {
            NotificationManager.error("Gebruikersnaam bevat ongeldige tekens")
            return;
        }
        if (user.firstName === undefined || user.firstName === "" || user.firstName.length < 1) {
            NotificationManager.error("Voornaam mag niet leeg zijn!")
            return;
        }
        if (user.lastName === undefined || user.lastName === "" || user.lastName.length < 1) {
            NotificationManager.error("Achternaam mag niet leeg zijn!")
            return;
        }
        if (user.email === undefined || user.email === "" || user.email.length < 1) {
            NotificationManager.error("Email mag niet leeg zijn!")
            return;
        }

        user.username = user.username.trim();
        user.firstName = user.firstName.trim();
        user.email = user.email.trim();
        user.lastName = user.lastName.trim();

        doFetch<IUser[]>("/api/user/admin/update", "POST", JSON.stringify(user), {
            "Content-Type": "application/json",
        }).then((res) => {
            setUsersWithPlaceholder(res);
            NotificationManager.success("Gebruiker " + user.username + " is gewijzigd!")
        });
    }

    const handleReset = (user) => {
        NotificationManager.warning("Ben je zeker dat je " + user.username + "'s wachtwoord wilt resetten?", 'Klik hier.', 5000, () => {
            doFetch<IUser[]>("/api/user/password/reset", "POST", JSON.stringify(user), {
                "Content-Type": "application/json",
            }).then((res) => {
                setUsersWithPlaceholder(res);
                NotificationManager.info("Wachtwoord van " + user.username + " is gereset.")
            });
        });
    }

    const handleDelete = (user) => {
        NotificationManager.warning("Ben je zeker dat je " + user.username + " wilt verwijderen?", 'Klik hier.', 5000, () => {
            deleteUser(user);
        });
    }

    const deleteUser = (user) => {
        //TODO implement check on your own user, so you can't delete yourself.
        //TODO implement check on admin, so you can't delete admins, or something
        user.itemState = "DELETED";
        doFetch<IUser[]>("/api/user/delete", "POST", JSON.stringify(user), {
            "Content-Type": "application/json",
        }).then((res) => {
            setUsersWithPlaceholder(res);
            NotificationManager.warning("Gebruiker " + user.username + " is verwijderd.")
        });
    }

    const handleAdmin = (user, admin) => {
        //TODO add frontend check to disable button on yourself.
        if (admin)
            NotificationManager.warning("Ben je zeker dat je " + user.username + " admin wilt maken?", 'Klik hier.', 5000, () => {
                makeAdmin(user, admin);
            });
        else
            NotificationManager.warning("Ben je zeker dat je de admin rechten van" + user.username + " wilt verwijderen?", 'Klik hier.', 5000, () => {
                makeAdmin(user, admin);
            });
    }

    const makeAdmin = (user, admin) => {
        //TODO implement check on your own user, so you can't delete yourself.
        //TODO implement check on admin, so you can't delete admins, or something
        doFetch<IUser[]>("/api/user/admin", "POST", JSON.stringify(user), {
            "Content-Type": "application/json",
        }).then((res) => {
            setUsersWithPlaceholder(res);
            NotificationManager.succes("Wijziging doorgevoerd")
        });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (search !== "") {
                doFetch<IUser[]>("/api/user/search", "POST", search, {
                    "Content-Type": "application/json",
                }).then((res) => {
                    setUsers([]);
                    setUsers(res);
                });
            } else {
                findAllUsers()
            }
        }
    }

    return (
        <div className={"admin-child"}>
            <CustomInputForm type={"text"} placeholder={"Zoek gebruiker(s)"} name={"search"} value={search} onChange={e => setSearch(e.target.value)}
                             onKeyDown={e => handleKeyDown(e)}/>

            {users.map((user, i) =>
                <UserPropertiesAdmin key={i} user={user} handleReset={handleReset} handleSubmit={handleSubmit} handleDelete={handleDelete} handleAdmin={handleAdmin}/>)}
        </div>
    );
};

export default UserAdmin;